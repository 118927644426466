import React from 'react'
import Bottom from './Bottom.jsx'
function Contact(){
    return(
        <div>
						           <title> Contact us -  McCann</title>

            <div id="primary" class="content-area">
	<main id="main" class="site-main">

		<section class="innerBanner about-us">
		<div class="container">
		  <div class="innerBannerContent">
		    <h2>Contact Us</h2>
		    <ul>
		      <li><a href="http://yssuccess.com/finance">Home</a></li>
		      <li><a href="#">Contact Us</a></li>
		    </ul>
		  </div>
		</div>
		</section>
	
	  <section class="contactPage">
	    <div class="container">
			<div className="row">
	    
	        <div class="contactLeft col-md-6">
	          <div class="line"></div>
	          <h2>Contact Info</h2>
	          <p>Here at McCann Financial Group we are always pleased to advise on any queries that you may have. 
For general enquiries you can email us at <a href="mailto:admin@mccannfg.com.au">admin@mccannfg.com.au</a>, phone us at 03 9326 6599, or simply visit our office in North Melbourne.</p>
	          <div class="contactLocations" >
	            <div class="location1" style={{width:"60%"}}>
	              <h5>Main Office</h5>
	              
				  <p>
	                  <span class="locLeft" style={{fontWeight:'bold'}}>Location:</span>
	                  <span style={{color:"#444", marginLeft:"10px"}}>1st Floor 36-38 Errol Street, North Melbourne, VIC 3051</span>
				<br></br></p>
	                 <p> <span style={{fontWeight:'bold'}}>Phone:</span>
	                  <a href="tel:0393266599"><span style={{color:"#444", marginLeft:"10px"}}>03 9326 6599</span></a>
	              	<br></br></p>
					  <p>  <span class="locLeft" style={{fontWeight:'bold'}}>Email:</span>
	                  <a href="mailto:admin@mccannfg.com.au"><span style={{color:"#444", marginLeft:"10px"}}>admin@mccannfg.com.au </span></a>                    
					  </p>
	            </div>
	        
	            
	            </div>
	         
	        </div>
	        <div class="contactRight col-md-6">
	          <div class="line"></div>
	          <h2>Send Us A Message</h2>
              <form action="https://emails.zutech.com.au/Success" id="contact-form" method="post">
	            <div class="fwrap">
	              <div class="fgrp">
	                <input type="text" name="Name" id="Name" placeholder="Name"></input>
	              </div>
	              <div class="fgrp">
	                <input type="email" name="Email" id="Email" placeholder="Email"></input>
	              </div>
	            </div>
	            <div class="fwrap">
	              <div class="fgrp">
	                <input type="text" name="Phone" id="Phone" placeholder="Phone"></input>
	              </div>
	              <div class="fgrp">
	                <input type="text" name="Subject" id="Subject" placeholder="Subjects"></input>
	              </div>
	            </div>
	            <div class="fgrp ftarea">
	              <textarea placeholder="Message" id="Message" rows="5"></textarea>
	            </div>
	            <div class="subBtn">
                    <input type="submit" name="" value="Send"></input>
                    
                </div>
	          </form>
	      </div>
	    </div>
		</div>
	  </section>


	</main>
</div>

            <Bottom></Bottom>
        </div>
    )
}
export default Contact;