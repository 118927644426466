import React from 'react'
import Bottom from './Bottom'

function About(){
    return(
        <div>
           <title>About -  McCann</title>

<div id="primary" class="content-area">
	<main id="main" class="site-main">

	
		<section class="innerBanner about-us">
			<div class="container">
			  <div class="innerBannerContent">
			    <h2>About Us</h2>
			    <ul>
			      <li><a href="http://yssuccess.com/finance">Home</a></li>
			      <li><a href="#">About Us</a></li>
			    </ul>
			  </div>
			</div>
		</section>
		<section class="welcomeSec welCmAb">
		    <div class="container">
		      <div class="welcomeFlex">
		        <div class="welcomeLeft">
		          <div class="line"></div>
		          <h2>Welcome to McCann Financial Services</h2>
		          <p>We are a group of vibrant and energetic accounting and taxation professionals backed by technical expertise and in-depth sector knowledge. </p>
				<p> 
				<span style={{fontWeight:"bold"}}>Our Values are: </span><br></br>	• Personal Service<br></br>
				• Accuracy<br></br>
				• Professionalism<br></br>
				• Constructive Business Advice<br></br>
				• Ethics<br></br>

				</p>
<p>We specialise in tailoring financial service solutions for individuals and small businesses. Whether you are an employee, a sole trader, a partnership, a family trust, or a larger company employing many staff, we have the Professional Staff, Knowledge & Resources to assist you in the following areas:</p>
<p>– Tax returns and compliance matters<br></br>
– Personal and business tax planning and structuring<br></br>
– Bookkeeping, payroll, preparation of financial statements& BAS’s<br></br>
– Audit and assurance services for financial statements, SMSFs, trust accounts, and Not-for-Profits
</p>
		        </div>
		        <div class="welcomeRight">
		          <div class="vidWrap">
		            <figure>
		              <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/vidDummy.png"} alt=""></img>
		            </figure>
		          </div>
		        </div>
		      </div>
		    </div>
		</section>
		  {/* <section class="skillsSec">
		    <div class="">
		      <div class="skillsFlex">
		        <div class="skillsLeft">
		          <figure>
		            <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/skills.png"} alt=""></img>
		          </figure>
		        </div>
		        <div class="skillsRight">
		          <div class="line"></div>
		          <h2>Our Skills?</h2>
		          		          <ul class="skillListing">
		            <li>
		              <h4>Financial Services</h4>
		              <div class="progressBar">
		                <div class="myProgress">
		                  <div class="myBar"></div>
		                </div>
		                <div class="progPercent">
		                  <h3>95%</h3>
		                </div>
		              </div>
		            </li>
		            <li>
		              <h4>Secure Future</h4>
		              <div class="progressBar">
		                <div class="myProgress">
		                  <div class="myBar" style={{width: "77%"}}></div>
		                </div>
		                <div class="progPercent">
		                  <h3>77%</h3>
		                </div>
		              </div>
		            </li>
		            <li>
		              <h4>Problem Solving</h4>
		              <div class="progressBar">
		                <div class="myProgress">
		                  <div class="myBar" style={{width: "60%"}}></div>
		                </div>
		                <div class="progPercent">
		                  <h3>60%</h3>
		                </div>
		              </div>
		            </li>
		            <li>
		              <h4>Finance Support</h4>
		              <div class="progressBar">
		                <div class="myProgress">
		                  <div class="myBar" style={{width: "80%"}}></div>
		                </div>
		                <div class="progPercent">
		                  <h3>80%</h3>
		                </div>
		              </div>
		            </li>
		          </ul>
		        </div>
		      </div>
		    </div>
		  </section> */}
 

	</main>
</div>
<Bottom></Bottom>
        </div>
    )
}
export default About;