import './App.css';
import {
  HashRouter,
  Route
} from "react-router-dom";
import Home from './Home.jsx'
import About from './About.jsx'
import Advisors from './Advisors'
import Contact from './Contact'

import Services from './Services'




import * as ReactBootstrap from "react-bootstrap";
export default function App() {
  return (
    
      <div>
    <header id="masthead" class="site-header">
		<div class="topHeadWrap">
			<div class="container topHeader">
		        <div class="topHeaderLeft">
		          <ul>
		            <li>
		              <a href="tel:03 9326 6599">
		                <figure>
		                 
                        <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/callIcon.png"} alt="callicon"></img>
		                </figure>
		                <span>03 9326 6599</span>
		              </a>
		            </li>
		            <li>
		              <a href="mailto:admin@mccannfg.com.au">
		                <figure>
                        <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/emailIcon.png"} alt="callicon"></img>
		                </figure>
		                <span>admin@mccannfg.com.au</span>
		              </a>
		            </li>
		          </ul>
		        </div>
		        <div class="topHeaderRight">
		          <ul>
		          								<li>
				              <a href="#" target="_blank">
				                <figure>
				                  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/fbIcon.png"} alt="socail-icon"></img>
				                </figure>
				              </a>
				            </li>
													<li>
				              <a href="#" target="_blank">
				                <figure>
				                  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/googleIcon.png"} alt="socail-icon"></img>
				                </figure>
				              </a>
				            </li>
													<li>
				              <a href="#" target="_blank">
				                <figure>
				                  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/instaIcon.png"} alt="socail-icon"></img>
				                </figure>
				              </a>
				            </li>
													<li>
				              <a href="#" target="_blank">
				                <figure>
				                  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/twitterIcon.png"} alt="socail-icon"></img>
				                </figure>
				              </a>
				            </li>
								          </ul>
		        </div>
            </div>


		      </div>
       
	</header>


    <HashRouter>
	<ReactBootstrap.Navbar bg="light" variant="light">
    <ReactBootstrap.Navbar.Brand href="#home" class="image-b">
						<img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/logo.png" }alt="logo" style={{width:"156px"}}></img>
					</ReactBootstrap.Navbar.Brand>
    <ReactBootstrap.Nav>
      <ReactBootstrap.Nav.Link href="#/Home">Home</ReactBootstrap.Nav.Link>
      <ReactBootstrap.Nav.Link href="#/About">About</ReactBootstrap.Nav.Link>
      <ReactBootstrap.Nav.Link href="#/Advisors">Advisors</ReactBootstrap.Nav.Link>
	  <ReactBootstrap.Nav.Link href="#/Contact">Contact</ReactBootstrap.Nav.Link>
      <ReactBootstrap.Nav.Link href="#/Services">Services</ReactBootstrap.Nav.Link>
	  <ReactBootstrap.Nav.Link href="#/">Career</ReactBootstrap.Nav.Link>

    </ReactBootstrap.Nav>
    
  </ReactBootstrap.Navbar>

  
<Route 
          exact 
          path="/" 
          component={Home}>
       </Route>


<Route path="/Home" component={Home}></Route>

<Route path="/About" component={About}></Route>

<Route path="/Contact" component={Contact}></Route>

<Route path="/Advisors" component={Advisors}></Route>
<Route path="/Services" component={Services}></Route>



        
     
        </HashRouter>
      
      </div>
    
  );
}




