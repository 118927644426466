import React from 'react'
function Bottom(){
    return(
        <div>
 <section class="questionSec">
		    <div class="container">
		      <div class="questionFlex">
		        <div class="questionLeft">
		          <h2>Have any Question?</h2>
		          <span>Have Free Updates and Tutorials Right in Your Email Inbox</span>
		        </div>
		        <div class="questionRight">
		          <form class="questForm" action="https://emails.zutech.com.au/News" id="contact-form" method="post">
		            <div class="formWrap">
		          
                          <input type="email" name="" id="Email" placeholder="Email Address"></input>
		              <input type="submit"  name="" value="Submit"></input>
		            </div>
		          </form>
		        </div>
		      </div>
		    </div>
		  </section>
		

	

		<footer id="colophon" class="site-footer">
			<div class="topFooter">
		      <div class="container">
		        <div class="topFooterFlex">
		          <div class="topFooterItem topFooterItem1">
		            <div class="line"></div>
		            <h2>About Us</h2>
		            <p>We are a group of vibrant and energetic accounting and taxation professionals backed by the technical expertise and in-depth sector knowledge. We uphold personalisation, efficiency, accuracy whilst adhering to ethics in everything we do.<br></br>
<a href="index.html">Read More&#8230;</a></p>
		          </div>
		       
		          <div class="topFooterItem topFooterItem4">
		            <div class="line"></div>
		            <h2>Get in touch</h2>
		            <address>
		              <ul>
		                <li>
		                  <span class="addLeft">Address:</span>
		                  <span class="addRight">Level 1, 36-38 Errol Street <br></br> North Melbourne VIC 3051</span>
		                </li>
		                <li>
		                  <span class="addLeft">Phone:</span>
		                  <span class="addRight"><a href="tel:03 9326 6599">03 9326 6599</a></span>
		                </li>
		                <li>
		                  <span class="addLeft">Email:</span>
		                  <span class="addRight"><a href="mailto:admin@mccannfg.com.au">admin@mccannfg.com.au</a></span>
		                </li>
		              </ul>
		            </address>
		            <div class="footerSocial">
		              <ul>
			          									<li>
					              <a href="#" target="_blank">
					                <figure>
					                  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/fbIcon.png"} alt="socail-icon"></img>
					                </figure>
					              </a>
					            </li>
															<li>
					              <a href="#" target="_blank">
					                <figure>
					                  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/googleIcon.png"} alt="socail-icon"></img>
					                </figure>
					              </a>
					            </li>
															<li>
					              <a href="#" target="_blank">
					                <figure>
					                  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/instaIcon.png"} alt="socail-icon"></img>
					                </figure>
					              </a>
					            </li>
															<li>
					              <a href="#" target="_blank">
					                <figure>
					                  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/twitterIcon.png"} alt="socail-icon"></img>
					                </figure>
					              </a>
					            </li>
										          </ul>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		    <div class="bottomFooter">
		      <div class="container">
		        <div class="bottomFooterFlex">
		          <div class="bottomFooterLeft">
		            <p>© Copyrights <a href="#">McCann Taxation Services</a> 2020</p>
		          </div>
		          <div class="bottomFooterRight">
		            <p>Website Design by <a href="#">McCann Taxation Services</a></p>
		          </div>
		        </div>
		      </div>
		    </div>
		</footer>
	
        </div>
    )
}
export default Bottom;