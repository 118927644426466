
import React from 'react'
import Bottom from './Bottom'

function Advisors(){
    return(
        <div>
			           <title>Advisors -  McCann</title>

<div id="primary" class="content-area">
	<main id="main" class="site-main">


		<section class="innerBanner about-us">
		<div class="container">
		  <div class="innerBannerContent">
		    <h2>Our Advisors</h2>
		    <ul>
		      <li><a href="http://yssuccess.com/finance">Home</a></li>
		      <li><a href="#">Our Advisors</a></li>
		    </ul>
		  </div>
		</div>
		</section>
		<section class="advisorSec">
			<div class="container">
			  <div class="line"></div>
			  <h2>Our Advisors</h2>
			  <div class="advisorFlex">
			  						    <div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/1.jpg"} alt="Phil McCann"></img>
					        </figure>
												      </div>
					      <h3>Phil McCann</h3>
					      <span>FCPA – Principal</span>
					    </div>
				    					    <div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/4.jpg"} alt="Kevin Liu"></img>
					        </figure>
												      </div>
					      <h3>Kevin Liu</h3>
					      <span>CPA  – Principal</span>
					    </div>
				    					    <div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/3.jpg"} alt="Daisy Liu"></img>
					        </figure>
												      </div>
					      <h3>Daisy Liu</h3>
					      <span>Senior Accountant</span>
					    </div>
				    					    <div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/2.jpg"} alt="Sonu GEER"></img>
					        </figure>
												      </div>
					      <h3>Sonu GEER</h3>
					      <span>Senior Accountant</span>
					    </div>
						<div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/vector.png"} alt="Phil McCann"></img>
					        </figure>
												      </div>
					      <h3>Sherry Li</h3>
					      <span>Accountant</span>
					    </div>
						<div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/vector.png"} alt="Phil McCann"></img>
					        </figure>
												      </div>
					      <h3>Xin Chen</h3>
					      <span>Accountant</span>
					    </div>

						<div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/vector.png"} alt="Phil McCann"></img>
					        </figure>
												      </div>
					      <h3>Alva Xu</h3>
					      <span>Accountant</span>
					    </div>
						<div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/vector.png"} alt="Phil McCann"></img>
					        </figure>
												      </div>
					      <h3>Rashmi Maharjan</h3>
					      <span>Accountant</span>
					    </div>
						<div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/vector.png"} alt="Phil McCann"></img>
					        </figure>
												      </div>
					      <h3>Jason Zheng</h3>
					      <span>Accountant</span>
					    </div>
						   <div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/vector.png"} alt="Phil McCann"></img>
					        </figure>
												      </div>
					      <h3>Sybil Li</h3>
					      <span>Accountant</span>
					    </div>
						<div class="advisorItem">
					      <div class="advisorImg">
								  
					        <figure>
					          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/vector.png"} alt="Phil McCann"></img>
					        </figure>
												      </div>
					      <h3>Maggie Wu</h3>
					      <span>Receptionist </span>
					    </div>
				    			  </div>
			</div>
		</section>

	</main>
</div>
<Bottom></Bottom>
</div>
)
}
export default Advisors;