import React from 'react'
import Bottom from './Bottom'

function Services(){
    return(
        <div>
			 <title> Services -  McCann</title>
<div id="primary" class="content-area">
	<main id="main" class="site-main">

		<section class="innerBanner about-us" >
		<div class="container">
		  <div class="innerBannerContent">
		    <h2>Our Services</h2>
		    <ul>
		      <li><a href="http://yssuccess.com/finance">Home</a></li>
		      <li><a href="http://yssuccess.com/services">Our Services</a></li>
		    </ul>
		  </div>
		</div>
		</section>
		<section class="serviceSec servicepage">
			<div class="container">
			  <div class="line"></div>
			  <h2>Our Services</h2>
			  <div class="serviceFlex">
			  					
				 				  		<h3 id="tax-services">Tax Services</h3>
				  		<p><p>
						  At McCann Financial Group, we offer tailored solutions to help you stay on top of your compliance matters and ensure you get the best tax outcomes, while also meeting your commercial objectives.<br></br><br></br>
From simple individual tax returns and Self Managed Super Funds to family owned enterprises or large proprietary companies, we help you navigate the complexities of Australia’s tax system while keeping the lodgement process simple and stress-free for you.<br></br><br></br>
We act with integrity in all we do. As registered Tax Agents, our accountants adhere to all ethical standards and codes of professional conduct established by the Tax Practitioners Board.<br></br>

						  </p>
						  <p style={{fontWeight:"bold"}}>Our taxation services include:</p>
<ul>
<li> Income Tax Returns for Individuals, Partnerships, Trusts, Companies and SMSFs</li>
<li> Business Activity Statements (BASs) and Instalment Activity Statements (IAS’s. PAYGI’s)</li>
<li> Annual Reconciliations: GST, Payroll, WorkCover, Superannuation, Payroll Tax etc.</li>
<li> Assistance with ATO audits, reviews and private rulings</li>
<li> 	Attend to TFN, ABN, GST, PAYG withholding, Business Names, Wine Equalization Tax, Fuel Tax Credits & all other registrations

<br></br><br></br> <span style={{fontWeight:"bold"}}>To secure a fast, efficient and thorough handling of your tax return, get in touch with us to see how we can help.</span></li>

</ul>
</p>
										 
				 				  		<h3 id="personal-tax-advice">Personal Tax Advice</h3>
				  		<p><p>We are here to not only help your everyday needs, but to also make sure you’re confident as your financial situation becomes more complex and your career matures. We factor in you short and long term goals, as well as your current and future financial capabilities so that we can approach all advice with an awareness of your overall tax and financial position.

</p>
<p>Examples of personal tax planning services offered by McCann Financial Group are as follows:</p>
<ul>
<li>	Identifying potential tax deductions, offsets and rebates to maximise tax refund or minimise tax payable</li>
<li>	Providing advice on the tax implications of investments including properties and shares and planning for capital gains tax on disposal</li>
<li>Optimising tax position when living or working abroad</li>
<li>	Advice on inheritance tax issues and estate planning</li>

</ul>
</p>
				 				  		<h3 id="accounting-and-financial-reporting-for-businesses">Accounting and Financial Reporting for Businesses</h3>
				  		<p><p>At McCann Financial Group, we absorb the complexity and shoulder your compliance burden so that you can focus on the day-to-day running of your business whilst achieving a healthy and sustainable growth.</p>
<p>Our team of Accountants are experienced and methodical in all the complexities of staying ahead of accounting and financial reporting obligations, including:</p>
<ul>
<li>	Bookkeeping and Payroll services</li>
<li>	Accounting software setup and ongoing training (Xero, MYOB, Quickbooks etc)</li>
<li>	Annual and interim Financial Reports and Periodical Management Accounts for small to medium enterprises(SME) for presentation to management, boards of directors, banks and councils</li>
<li>	Special purpose financial reports for specific stakeholders such as Not-for-Profit organisations</li>
<li>	Compliance requirements associated with indirect taxes
</li>
</ul>
</p>
				 				  		<h3 id="business-planning">Business Planning</h3>
				  		<p><p>At McCann Financial Group, we’ve been advising small businesses across a wide range of industry sectors and business types for more than 32 years.
<br></br><br></br>Equipped with the right methodologies and procedures, we help and support small to medium businesses (‘SME’) increase efficiencies, reduce costs and maintain financial health to achieve their desired business objectives.
</p>
<p>This includes support with:</p>
<ul>
<li>	Arranging the formation of tax-effective business structures tailored to your personal and family circumstances: 
	<br></br>
	Companies, Family Trusts, Unit Trusts, Self Managed Super Funds.
	In some cases our dedicated team can have your new company in a few 	hours !</li>
<li>Advising on the application and implications of employment taxes</li>
<li>Consultation on ATO financial reporting benchmarks</li>
<li>Financial forecasting and budgeting</li>
<li>Cash Flow Management</li>

</ul>
</p>
				 				  		<h3 id="audit---assurance-services">Audit & Assurance Services</h3>
				  		<p><p>McCann Financial Group provides more than a signed auditor’s report and opinion.<br></br><br></br>
With a group of qualified, trusted auditors, McCann Financial Group can deliver a robust and independent audit that not only ensures you meet your statutory obligations, but also help identify business improvements and possible areas of risk.<br></br><br></br>
Working closely with our clients, we turn our focus from the analysis of detailed accounting entries to key processes and business issues.<br></br><br></br>
</p>
<p>We are qualified and experienced in conducting audits for a wide range of accounts, including:</p>
<ul>
<li>	Annual independent audit for Self-managed superannuation funds (SMSFs)</li>
<li>Trust Audits for Lawyers, Accounting practices, Real Estate agents</li>
<li>Financial statement audits for family owned enterprises,  and entities registered under the Australian Charities and Not-for-profits Commission</li>
<li>Through our affiliated networks we can arrange & liaize with audits of large proprietary companies</li>
</ul>
</p>
										
				 				  		<h3 id="rto-services">RTO-Services</h3>
				  		<p><p>We have expertise in providing tailored accounting services to the specific needs of the RTO industry, be it in Vocational Education and Training (VET), or Commonwealth Register of Institutions and Courses for Overseas Students (CRICOS)<br></br><br></br>
Our specialist team will work with you to establish the important foundations of your Registered Training Organisation (RTO) business, with a focus on financial viability tools, and cash projections.<br></br><br></br>
With stringent compliance regulations and evolving requirements, the RTO industry is certainly more complex than it seems. At MFG we will guide you through and manage all your financials and compliance matters, ensuring your business is structured correctly, with sound financials to allow for growth.<br></br><br></br>
McCann Financial group specialises in providing financial and accounting services to businesses registering or registered for technical and Vocational Education and Training (VET) and Commonwealth Register of Institutions and Courses for Overseas Students (CRICOS). Whether you are running an existing RTO business or in the process of starting an RTO business, our accountants are equipped with skills, knowledge and expertise to provide best possible outcomes for your business.<br></br><br></br>
</p>
<p>Services we offer at MFG<br />
  Financial viability tools, including business plan and other relevant documents<br />
• Budgeting and Cash Flow projection<br />
• Financial forecast preparation and or review<br />
• Financial analysis requirements<br />
• Business set up and structuring ( we can form your Company and/or Trust )<br />
• Advice on business structure<br />
 Keep your taxation obligation up to date, be it Business Activity statement, or Company Tax Returns. </p>
<p>Setting up accounting software such as Xero, RTO Manager etc.</p>
</p>
<h3 id="Software-advice-and-assistance">Software Advice and Assistance</h3>
				  		<p><p>There are a variety of bookkeeping and accounting software options available on the market today. With the numerous ubiquities of internet, you may be wondering what would best suit your situation. Here at MFG, we can assist you with advice, assistance and training with various accounting, bookkeeping and taxation software packages. This could include finding the software system that is best for your business, be it small or medium. We can help your business generate meaningful reports in relation to management, financial accounting reporting or tax.</p></p>
										  
				 				  		<h3 id="budgeting-and-cash-flow-analysis">Budgeting and Cash Flow Analysis</h3>
				  		<p><p>
						  Cash flow is one of the most important aspects of any business. Without money moving through a business or organisation efficiently, things suddenly come to a standstill.<br></br>
						  <br></br>

Developing a comprehensive budget and efficient cash flow is essential for effectively growing and managing your business. Through analysing and adjusting your cash flow to suit your requirements, you can not only invest in your business, but also project tax liabilities and identify issues affecting your profits.<br></br>
<br></br>

We can help businesses of all sizes with their budgeting and cash flow analysis, providing clients with professional support and help when required.<br></br>

<br></br>
This includes assistance with Budgets / Projections for banks, together with associated forms & paperwork.
 </p>
</p>

				  		<p> <span style={{fontSize: "24px",    fontFamily: "'Montserrat', sans-serif",
    color: "#444444",
    lineHeight: "1em",
    margin: "1em 0 0.7em 0",
    fontWeight: "700"}}>
							  Loans
						  </span><br></br><br></br>
In our firm we have a Licenced Loan Broker, who can assist you with Home Loans and other Commercial, Personal or Motor Vehicle borowings.
</p>
<p style={{margin: "1em 0 0.7em 0"}}> <span style={{fontSize: "24px",    fontFamily: "'Montserrat', sans-serif",
    color: "#444444",
    lineHeight: "1em",
    margin: "1em 0 0.7em 0",
    fontWeight: "700"}}>
							  Our Offices
						  </span><br></br>
						  -	North Melbourne<br></br>
-	Camberwell<br></br>

</p>

						
								  </div>
		
</div>
</section>
	</main>

            </div>
<Bottom></Bottom>
        </div>
    )
}
export default Services;