import React from 'react'
import Bottom from './Bottom'
function Home(){
    return(
        <div>
           <title>McCann - Financial focus and Tax Services</title>
              <section id="slider" class="slider">
	    <ul>
	    				        <li>
			          <figure>
                          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/banner.png"} alt=""></img>
			          </figure>
			            <div class="container">
			                <div class="ban_content">
			                    <h1>Financial Focus,Taxation & Consulting</h1>
			                    <p>With Over 30 years of experience in helping businesses to find comprehensive solutions.</p>
			                    <div class="btns">
			                      <a href="#/About" class="btn btn1">Learn More</a>
			                      <a href="#/Contact" class="btn">Contact Us</a>
			                  </div>
			                </div>
			            </div>
			        </li>
	            		
	            	    </ul>        
	  </section>
 
    <section class="sliderBottomSec">
      <div class="container sliderBottomPadding">
        <div class="sliderBottomFlex">
        						<div class="sliderBottomItem">
			            <figure>
                          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/serv1.png"} alt=""></img>
			            </figure>
			            <div class="sliderBottomOverlay">
			              <h4>Doing Tax Returns  </h4>
			              <p><span ><img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/aa.png"} style={{width:"20px"}}></img> Individual Tax returns</span><br />
						  <span><img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/aa.png"} style={{width:"20px"}}></img> Business Tax returns</span><br />
						  <span><img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/aa.png"} style={{width:"20px"}}></img> Financial reporting</span><br />
						  <span><img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/aa.png"} style={{width:"20px"}}></img> Business Activity Statements</span></p>
			              <a href="#/About">Read More</a>
			            </div>
			         </div>
									<div class="sliderBottomItem">
			            <figure>
			             
                          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/serv2.png"} alt=""></img>
			            </figure>
			            <div class="sliderBottomOverlay">
			              <h4>Starting Business </h4>
			              <p><img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/aa.png"} style={{width:"20px"}}></img>  Structure setup<br />
<span style={{paddingLeft:"20px"}}>● Company Registration</span>
<br />
<span style={{paddingLeft:"20px"}}>● Trust Registration</span><br />
<span style={{paddingLeft:"20px"}}>● Corporate Finance</span></p>
			              <a href="#/About">Read More</a>
			            </div>
			         </div>
									<div class="sliderBottomItem">
			            <figure>
			             
                          <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/serv3.png"} alt=""></img>
			            </figure>
			            <div class="sliderBottomOverlay">
			              <h4>Audit and Assurance </h4>
			              <p>
						  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/aa.png"} style={{width:"20px"}}></img>  RTO be it internal or external<br></br>
						  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/aa.png"} style={{width:"20px"}}></img>  RE Trust<br></br>
						  <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/aa.png"} style={{width:"20px"}}></img>  Trust Account  Audits:<br></br>
 <span style={{paddingLeft:"20px"}}>●	Lawyers</span><br></br>
 <span style={{paddingLeft:"20px"}}>●	Accountants</span><br></br>
 <span style={{paddingLeft:"20px"}}>●	Real Estate Agents</span><br></br>

</p>
			              <a href="#/About">Read More</a>
			            </div>
			         </div>
				        </div>
      </div>
    </section>

	<section class="welcomeSec">
	    <div class="container">
	      <div class="welcomeFlex">
	        <div class="welcomeLeft">
	          <div class="line"></div>
	          <h2>Welcome to McCann Financial Services</h2>
	          <p style={{textAlign:"justify"}}>We are a group of Certified Practicing Accountants (CPAs) located in North Melbourne, specialising in Taxation and Accounting services for individuals and small to medium enterprises.<br />
At McCann Financial Group, our vision is to make your life easier by providing forward-thinking service. We draw on our vast tax expertise to provide unparalleled service, supported by years of experience, knowledge and understanding of tax legislation. We understand that the world of taxation can be very complex and ever-changing, so we don&#8217;t believe in ‘one approach fits all’. Tax compliance is very much intertwined with your personal or business wealth creation. Your life is not simply a list of boxes to tick off on the checklist, so we take the time to understand your financial position and goals to ensure you achieve the most tax-effective outcome and long-term value creation whilst complying with all regulatory requirements.</p>
	          <div class="btns">
	            <a href="#/About" class="btn">Know More</a>
	          </div>
	        </div>
	        <div class="welcomeRight">
	          <div class="vidWrap">
	            <figure>
                      <img src={process.env.PUBLIC_URL+"./wp-content/uploads/2018/07/vidDummy.png"} alt=""></img>
	            </figure>
	          </div>
	        </div>
	      </div>
	    </div>
	</section>


  <section class="serviceSec">
    <div class="container">
	  <div class="line"></div>
	  <h2>Our Services</h2>
	  <div class="serviceFlex">
	  				    <div class="serviceItem" style={{position:"relative", paddingBottom:"60px"}}>
							      <span><i class="fa fa-university"></i></span>
			      <h4>Tax Services</h4>
			      <p>At McCann Financial Group, we offer tailored solutions to help you stay on top of your tax compliances and ensure you get the best tax outcomes, while also meeting your commercial objectives&#8230;.</p>
				  <div class="serviceReadMore"><a href="#/Services">Read More</a></div>
			    </div>
						    <div class="serviceItem" style={{position:"relative", paddingBottom:"60px"}}>
							      <span><i class="fa fa-university"></i></span>
			      <h4>Personal Tax Advice</h4>
			      <p>We are here to not only help your everyday needs, but to also make sure you’re confident as your financial situation becomes more complex and your career matures&#8230;.</p>
				  <div class="serviceReadMore"><a href="#/Services">Read More</a></div>
			    </div>
						    <div class="serviceItem" style={{position:"relative", paddingBottom:"60px"}}>
							      <span><i class="fa fa-university"></i></span>
			      <h4>Accounting and Financial Reporting for Businesses</h4>
			      <p>At McCann Financial Group, we absorb the complexity and shoulder your compliance burden so that you can focus on the day-to-day running of your business whilst&#8230;</p>
				  <div class="serviceReadMore"><a href="#/Services">Read More</a></div>
			    </div>
						    <div class="serviceItem" style={{position:"relative", paddingBottom:"60px"}}>
							      <span><i class="fa fa-university"></i></span>
			      <h4>Business Planning</h4>
			      <p>At McCann Financial Group, we’ve been advising small businesses across a wide range of industry sectors and business types for more than 20 years (?).</p>
				  <div class="serviceReadMore"><a href="#/Services">Read More</a></div>
			    </div>
						    <div class="serviceItem" style={{position:"relative", paddingBottom:"60px"}}>
							      <span><i class="fa fa-university"></i></span>
			      <h4>Audit & Assurance Services</h4>
			      <p>McCann Financial Group provides more than a signed auditor’s report and opinion with a group of qualified, trusted auditors, McCann Financial Group can deliver a robust and independent audit&#8230;</p>
				  <div class="serviceReadMore"><a href="#/Services">Read More</a></div>
			    </div>
						    <div class="serviceItem" style={{position:"relative", paddingBottom:"60px"}}>
							      <span><i class="fa fa-university"></i></span>
			      <h4>RTO-Services</h4>
			      <p>We have gained expertise in providing tailored accounting services to the specific needs of the RTO industry be it in Vocational Education and Training (VET), or Commonwealth Register&#8230;</p>
				  <div class="serviceReadMore"><a href="#/Services">Read More</a></div>
			    </div>
						    <div class="serviceItem" style={{position:"relative", paddingBottom:"60px"}}>
							      <span><i class="fa fa-university"></i></span>
			      <h4>Software Advice and Assistance</h4>
			      <p>There is a variety of bookkeeping and accounting software available on the market today. With numerous ubiquities of internet, you may be wondering what would best suit your situation&#8230;</p>
				  <div class="serviceReadMore"><a href="#/Services">Read More</a></div>
			    </div>
						    <div class="serviceItem" style={{position:"relative", paddingBottom:"60px"}}>
							      <span><i class="fa fa-university"></i></span>
			      <h4>Budgeting and Cash Flow Analysis</h4>
			      <p>Cash flow is one of the most conspicuous aspects of any business. Without money moving through a business or organisation efficiently, progress can suddenly come to a standstill&#8230;</p>
				  <div class="serviceReadMore"><a href="#/Services">Read More</a></div>
			    </div>
				  </div>
	</div>
  </section>
  {/* <section class="countingSec">
    <div class="container">
      <div class="countingFlex">
        <div class="countingLeft">
          <div class="line"></div>
          <h2>We help you grow your business</h2>
        </div>
        <div class="countingRight">
          <ul>
          						<li>
		              <h4>5673</h4>
		              <span>Happy Clients</span>
		            </li>
									<li>
		              <h4>2408</h4>
		              <span>Projects Completed</span>
		            </li>
									<li>
		              <h4>1200</h4>
		              <span>Hours Worked</span>
		            </li>
									<li>
		              <h4>25+</h4>
		              <span>Years of business</span>
		            </li>
				          </ul>
        </div>
      </div>
    </div>
  </section> */}
  <section class="requestConsultation" >
    <div class="container">
      <div class="consultForm">
        <div class="line"></div>
        <h2>Request a Free Consultation</h2>
        <p><p>Whatever types of questions you’ll have, feel free to get in touch with us and address them!</p>
</p>
        <form action="https://emails.zutech.com.au/Success" id="contact-form" method="post">
          <div class="formgroup">
            <input type="text" class="formcontrol" placeholder="Name*"></input>
          </div>
          <div class="formgroup">
            <input type="email" class="formcontrol" placeholder="Email Address*"></input>
          </div>
          <div class="formgroup">
            <input type="number" class="formcontrol" placeholder="Phone*"></input>
          </div>
          <div class="formgroup">
            <input type="text" class="formcontrol" placeholder="Subjects*"></input>
          </div>
          <div class="formgroup">
            <textarea class="formcontrol" rows="5" placeholder="Any Message*"></textarea>
          </div>
          <input type="submit" name="" value="Submit"></input>
        </form>
      </div>
    </div>
  </section>
  <section class="partnerSec">
    <div class="container">
      <ul>
      					<li>
		          <figure><img src="420.html" alt=""></img></figure>
		        </li>
							<li>
		          <figure><img src="419.html" alt=""></img></figure>
		        </li>
							<li>
		          <figure><img src="418.html" alt=""></img></figure>
		        </li>
							<li>
		          <figure><img src="417.html" alt=""></img></figure>
		        </li>
							<li>
		          <figure><img src="#" alt=""></img></figure>
		        </li>
							<li>
		          <figure><img src="#" alt=""></img></figure>
		        </li>
			      </ul>
    </div>
  </section>
 
<Bottom></Bottom>
</div>

      
    )
}
export default Home;